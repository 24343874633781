<template>
  <div class="all-page-container">
    <div class="header">
      <div class="left">
        <img src="~@/assets/image/logo.png">
        <span>全域监管平台</span>
      </div>
      <div class="right"><a-button type="primary" @click="close">关闭</a-button></div>
    </div>
    <div class="content">
      <div class="title-container">
        <div class="title">电梯事件</div>
        <div class="desc">电梯内部编号：{{liftcode||''}}</div>
      </div>
      <div class="box-container" style="margin-top: 10px;">
        <div class="box-container-inner">
          <div class="table-header">
            <h3 class="table-title">内容列表</h3>
            <div class="table-btns">
              <a-button type="primary">导出</a-button>
              <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
            </div>
          </div>
          <a-table :columns="tableColumns" :data-source="tableData" row-key="eventrecordid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
            <span slot="eventstatus" slot-scope="value">
              <a-tag :color="eventstatusColors[value]||''">{{eventstatus[value]||''}}</a-tag>
            </span>
            <span slot="eventstarttime" slot-scope="value">
              {{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}
            </span>
            <span slot="eventendtime" slot-scope="value">
              {{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}
            </span>
            <!-- <span slot="operation" slot-scope="value, record, index">
              <a-button type="link" size="small" @click="showModal('detail', value, record, index)">详情</a-button>
              <a-button type="link" size="small" @click="showModal('edit', value, record, index)">修改</a-button>
              <a-button type="link" size="small" @click="deleteConfirm(value, record, index)">删除</a-button>
            </span> -->
          </a-table>
          <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" style="margin-top: 20px; text-align: right;">
            <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import pagination from '@/mixins/pagination'
import {eventTypes,eventstatus,eventstatusColors} from '@/json/wlwhistory'
import {getEventrecordListByCondition} from "A/wlw";
export default {
  mixins: [pagination],
  props: ['liftnum', 'liftcode', 'devicenum'],
  data() {
    return {
      moment,
      eventstatus,
      eventstatusColors,
      tableColumns: [
        {
          title: '事件名称',
          dataIndex: 'thirdeventtypename',
          key: 'thirdeventtypename',
          ellipsis: true,
        },
        {
          title: '故障代码',
          dataIndex: 'thirdeventcode',
          key: 'thirdeventcode',
          ellipsis: true,
        },
        {
          title: '故障代码名称',
          dataIndex: 'thirdeventname',
          key: 'thirdeventname',
          ellipsis: true,
        },
        {
          title: '故障状态',
          dataIndex: 'eventstatus',
          key: 'eventstatus',
          ellipsis: true,
          scopedSlots: { customRender: 'eventstatus' }
        },
        {
          title: '上报时间',
          dataIndex: 'eventstarttime',
          key: 'eventstarttime',
          ellipsis: true,
          scopedSlots: { customRender: 'eventstarttime' }
        },
        {
          title: '解除时间',
          dataIndex: 'eventendtime',
          key: 'eventendtime',
          ellipsis: true,
          scopedSlots: { customRender: 'eventendtime' }
        },
        // {
        //   title: '操作',
        //   key: 'operation',
        //   scopedSlots: { customRender: 'operation' }
        // },
      ],
      tableData: [],
      tableLoading: false,
    }
  },
  created() {
    this.init();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    init() {
      this.getTableData();
    },
    getTableData(firstPage) {
      if(!this.devicenum) {
        return;
      }
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        devicenum: this.devicenum,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getEventrecordListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.all-page-container {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #F0F2F5;
  overflow-y: auto;
}
.header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  height: 50px;
  background: #fff;
  padding: 0 24px;
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
}
.left {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  img {
    height: 30px;
  }
  span {
    display: inline-block;
    margin-left: 8px;
    font-size: 12px;
    white-space: nowrap;
  }
}
.right {
  display: flex;
  align-items: center;
}
.content {
  padding-top: 50px;
}
.title-container {
  padding: 24px 40px;
  background-color: white;
  .title {
    font-size: 17px;
  }
  .desc {
    font-size: 13px;
    color: #999;
  }
}
</style>