<!--实时监控视频页面-->
<template>
  <div class="monitoring">
    <div class="elevator-infos">
      <div v-if="terminaltype!=10" class="video-view">
        <div class="video-operations">
<!--          <div>电梯编号: {{liftnum}}</div>-->
          <div></div>
          <div style="position:relative;float: right">
            <a-button size="small" style="margin-left: 8px;" v-if="playtype==0" @click="snapShot">标注</a-button>
            <a-button size="small" v-if="recordVisible==true&&playtype==0" @click="startRecord()" type="primary" style="margin-left: 8px">视频录制</a-button>
            <a-button size="small" v-else-if="recordVisible==false&&playtype==0" @click="stopRecord()" style="margin-left: 8px">录制完成</a-button>
            <a-button size="small" v-if="playtype==0" @click="cutPicture(true)" style="margin-left: 8px">视频截图</a-button>
            <a-button size="small" type="primary" @click="preview" style="margin-left: 8px">开始直播</a-button>
            <a-button size="small" type="primary" @click="hidePlayer" style="margin-left: 8px;margin-bottom: 5px">开始回放</a-button>
<!--            <a-button size="small" v-else style="margin-bottom:5px;margin-left: 8px;" type="primary" @click="stopPlayback">停止播放</a-button>-->
          </div>
        </div>
          <object v-if="playtype==1" type='application/x-vlc-plugin' id='vlc' events='false' :width=this.playerSize.width :height=this.playerSize.height pluginspage="http://www.videolan.org" codebase="http://downloads.videolan.org/pub/videolan/vlc-webplugins/2.2.2/npapi-vlc-2.2.2.tar.xz">
              <param name='mrl' value='' />
              <param name='volume' value='50' />
              <param name='autoplay' value='true' />
              <param name='loop' value='false' />
              <param name='fullscreen' value='true' />
          </object>
<!--          <video-->
<!--              v-if="playtype==0"-->
<!--              id="videoId"-->
<!--              controls-->
<!--              :height=this.playerSize.height-->
<!--              :width=this.playerSize.width>-->
<!--          </video>-->
        <div :style="{height:playerSize.height + 'px',width:playerSize.width + 'px',position: 'relative'}">
          <LivePlayer
              id="liveplayer"
              v-if="playtype==0"
              :videoUrl="videoUrl"
              fluent
              live
              autoplay
              alt=""
              aspect='fullscreen'
              :show-custom-button="false"
              :hide-snapshot-button="true"
          ></LivePlayer>
        </div>

        <div class="captureHoverArea" v-show="RECArea">
          <label id="blinkRec"><button></button><b>&nbsp;REC&nbsp;</b></label>
          <label id="recorderTime"></label>
        </div>
        <div id="processCanvas" style="position: relative; height: 100px;margin-top: -5px;background: #363636"></div>
        <canvas v-show="false" class="canvass" id="myCanvas" width="1029" height="600" onselectstart="return false;"></canvas>
      </div>
      <div v-if="terminaltype==10" class="elevator-door">
        <div class="video-operations" >
          <div style="margin: 2px;">电梯编号: {{liftnum}}</div>
        </div>
        <div class="door-image">
          <img v-if="cardsData.door_status=='01'" src="~@/assets/image/huichuan/door-close.gif">
          <img v-else-if="cardsData.door_status=='00'" src="~@/assets/image/huichuan/door-open.gif">
          <img v-else src="~@/assets/image/huichuan/door.png">
          <div style="position: absolute; top: 5.6%; width: 100%;">
            <div class="door-num">
              <div class="direction">
                <img v-if="cardsData.car_direction=='01'" src="~@/assets/image/huichuan/shangbtn.png">
                <img v-else-if="cardsData.car_direction=='02'" src="~@/assets/image/huichuan/xiabtn.png">
              </div>
              <div class="num">{{cardsData.car_position}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="info-view">
        <div class="tab-container tab-bottom0  tab-left0">
          <a-tabs v-model="activeKey" size="small">
            <a-tab-pane key="tab1" tab="基本数据"></a-tab-pane>
            <a-tab-pane key="tab2" tab="故障历史"></a-tab-pane>
            <a-tab-pane v-if="terminaltype!=10" key="tab3" tab="AI历史"></a-tab-pane>
            <a-tab-pane key="tab4" tab="报警历史"></a-tab-pane>
            <a-tab-pane key="tab5" tab="事件历史"></a-tab-pane>
            <a-tab-pane key="tab6" tab="参数设置"></a-tab-pane>
            <!-- <a-tab-pane key="tab5" tab="参数设置"></a-tab-pane> -->
          </a-tabs>
          <monitoring-base-info v-if="activeKey=='tab1'" :liftid="$route.params.liftid"></monitoring-base-info>
          <monitoring-event v-else-if="activeKey=='tab2'" key="malfunction" :devicenum="devicenum" page-type="malfunction" @upDate="change"></monitoring-event>
          <monitoring-event v-else-if="activeKey=='tab3'" key="event" :devicenum="devicenum" page-type="event" @upDate="change"></monitoring-event>
          <monitoring-event v-else-if="activeKey=='tab4'" key="alarm" :devicenum="devicenum" page-type="alarm" @upDate="change"></monitoring-event>
          <monitoring-event v-else-if="activeKey=='tab5'" key="elevator" :devicenum="devicenum" page-type="elevator" @upDate="change"></monitoring-event>
          <device-params v-else-if="activeKey=='tab6'" key="elevator" :devicenum="devicenum" page-type="elevator"></device-params>
        </div>
      </div>
    </div>
    <div class="status-cards" style="margin-top: 10px">
      <div class="card-container">
        <div class="card-title">
          <img src="~@/assets/image/monitoring/jxyx.png" alt="">
          <span>轿厢运行</span>
        </div>
        <div class="card-infos">
          <a-row>
            <!-- <a-col :span="8">
              <div class="info-desc">服务模式</div>
              <div class="info-data">{{cardsData?serviceModeMap[cardsData.service_mode]:''}}</div>
            </a-col> -->
            <a-col :span="8">
              <div class="info-desc">当前状态</div>
              <div class="info-data" :style="{color: cardsData?statusColors[cardsData.car_status]?statusColors[cardsData.car_status]:'':''}">{{cardsData?carStatusMap[cardsData.car_status]:''}}</div>
            </a-col>
            <a-col :span="8">
              <div class="info-desc">运行方向</div>
              <div class="info-data">{{cardsData?carDirectionMap[cardsData.car_direction]:''}}</div>
            </a-col>
            <a-col :span="8">
              <div class="info-desc">运行速度</div>
              <div class="info-data">{{cardsData?parseFloat(cardsData.realtime_speed).toFixed(2):''}}</div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="card-container">
        <div class="card-title">
          <img src="~@/assets/image/monitoring/wzxx.png" alt="">
          <span>位置信息</span>
        </div>
        <div class="card-infos">
          <a-row>
            <a-col :span="14">
              <div class="info-desc">开锁区域</div>
              <div class="info-data" :style="{color: cardsData?statusColors[cardsData.door_zone]?statusColors[cardsData.door_zone]:'':''}">{{cardsData?doorZoneMap[cardsData.door_zone]:''}}</div>
            </a-col>
            <a-col :span="10">
              <div class="info-desc">当前楼层</div>
              <div class="info-data">{{cardsData?cardsData.car_position:''}}</div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="card-container">
        <div class="card-title">
          <img src="~@/assets/image/monitoring/qita.png" alt="">
          <span>其它信息</span>
        </div>
        <div class="card-infos">
          <a-row>
            <a-col :span="6">
              <div class="info-desc">关门到位</div>
              <div class="info-data" style="width: auto" :style="{color: cardsData?statusColors[cardsData.door_status]?statusColors[cardsData.door_status]:'':''}">{{cardsData?doorStatusMap[cardsData.door_status]:''}}</div>
            </a-col>
            <a-col :span="6">
              <div class="info-desc">服务模式</div>
              <div class="info-data" :style="{color: cardsData?serviceModeColors[cardsData.service_mode]?serviceModeColors[cardsData.service_mode]:'':''}">{{cardsData?serviceModeMap[cardsData.service_mode]:''}}</div>
            </a-col>
            <a-col :span="6">
              <div class="info-desc">天气状况</div>
              <div class="info-data">{{text}}<span v-show="text"></span></div>
            </a-col>
            <a-col :span="6">
              <div class="info-desc">当前气温</div>
              <div class="info-data">{{temperature}}<span v-show="temperature">℃</span></div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="card-container">
        <div class="card-title">
          <img src="~@/assets/image/monitoring/kmcs.png" alt="">
          <span>开关门次数</span>
        </div>
        <div class="card-infos">
          <a-row>
            <a-col :span="12">
              <div class="info-desc">当日开关门</div>
              <div class="info-data">{{cardsData?cardsData.today_door_num:''}}</div>
            </a-col>
            <a-col :span="12">
              <div class="info-desc">累计开关门</div>
              <div class="info-data">{{cardsData?cardsData.accumulative_door_num:''}}</div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <div class="status-cards">
      <div class="card-container">
        <div class="card-title">
          <img src="~@/assets/image/monitoring/yxsj.png" alt="">
          <span>运行时间统计</span>
        </div>
        <div class="card-infos">
          <a-row>
            <a-col :span="12">
              <div class="info-desc">当日运行时间</div>
              <div class="info-data">{{cardsData?cardsData.today_run_time:''}}<span v-show="cardsData" style="font-size:14px;">分钟</span></div>
            </a-col>
            <a-col :span="12">
              <div class="info-desc">累计运行时间</div>
              <div class="info-data">{{cardsData?parseFloat(cardsData.accumulative_run_time/60).toFixed(2):''}}<span v-show="cardsData" style="font-size:14px;">小时</span></div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="card-container">
        <div class="card-title">
          <img src="~@/assets/image/monitoring/yxcstj.png" alt="">
          <span>运行次数统计</span>
        </div>
        <div class="card-infos">
          <a-row>
            <a-col :span="12">
              <div class="info-desc">当日运行次数</div>
              <div class="info-data">{{cardsData?cardsData.today_run_num:''}}</div>
            </a-col>
            <a-col :span="12">
              <div class="info-desc">累计运行次数</div>
              <div class="info-data">{{cardsData?cardsData.accumulative_run_num:''}}</div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="card-container">
        <div class="card-title">
          <img src="~@/assets/image/monitoring/yxlctj.png" alt="">
          <span>运行里程统计</span>
        </div>
        <div class="card-infos">
          <a-row>
            <a-col :span="12">
              <div class="info-desc">当日运行里程</div>
              <div class="info-data">{{cardsData?parseFloat(cardsData.today_mileage).toFixed(2):''}}<span v-show="cardsData" style="font-size:14px;">公里</span></div>
            </a-col>
            <a-col :span="12">
              <div class="info-desc">累计运行里程</div>
              <div class="info-data">{{cardsData?parseFloat(cardsData.cumulative_mileage).toFixed(2):''}}<span v-show="cardsData" style="font-size:14px;">公里</span></div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div v-if="terminaltype!=10" class="card-container">
        <div class="card-title">
          <img src="~@/assets/image/monitoring/rlltj.png" alt="">
          <span>乘梯人次统计</span>
        </div>
        <div class="card-infos">
          <a-row>
            <a-col :span="8">
              <div class="info-desc">当日乘梯人次</div>
              <div class="info-data">{{cardsData?cardsData.today_passenger_num:''}}</div>
            </a-col>
            <a-col :span="8">
              <div class="info-desc">累计乘梯人次</div>
              <div class="info-data">{{cardsData?cardsData.accumulative_passenger_num:''}}</div>
            </a-col>
            <a-col :span="8">
              <div class="info-desc">当前乘客数</div>
              <div class="info-data">{{cardsData?cardsData.passenger_num:''}}</div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <a-modal v-model="playbackVisible" title="选择时间" :centered="true">
      <template slot="footer">
        <a-button @click="cancelPlayBack">取消</a-button>
        <a-button type="primary" @click="start_playback">确认</a-button>
      </template>
      <div class="time-container">
        <div class="time-label">起止时间：</div>
        <div><a-range-picker v-model="timeRange" :showTime="true" /></div>
      </div>
    </a-modal>
    <a-modal v-model="markVisible" title="选择标注类型" :maskClosable="false" :closable="false" :centered="true">
      <template slot="footer">
        <a-button @click="cancelMark">取消</a-button>
        <a-button type="primary" @click="startMark">确认</a-button>
      </template>
      <a-form-model ref="markForm" :model="markFormDatas" :rules="markFormRules" :label-col="{span:7}" :wrapper-col="{span:14}">
        <a-form-model-item label="标注类型" prop="type1">
          <a-select v-model="markFormDatas.type1">
            <a-select-option value="00">开门检测</a-select-option>
            <a-select-option value="01">AI检测</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="markFormDatas.type1!='00'" label="AI参数" prop="type2">
          <a-select v-model="markFormDatas.type2">
            <a-select-option value="03">电瓶车进入轿厢</a-select-option>
            <a-select-option value="04">长时间挡门</a-select-option>
            <a-select-option value="05">轿厢内危险动作</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <monitoring-mark v-if="markpointImageVisible" @close="closeMarkpoint" :liftnum="liftnum" :liftcode="liftcode" :devicenum="devicenum" :mark-type1="markFormDatas.type1" :mark-type2="markFormDatas.type2" :image-src="snapShotImageSrc"></monitoring-mark>
  </div>
</template>
<script>
import LivePlayer from '@liveqing/liveplayer'
import {mapState} from 'vuex'
import moment from 'moment'
import MonitoringBaseInfo from './MonitoringBaseInfo'
import MonitoringEvent from './MonitoringEvent'
import MonitoringMark from './MonitoringMark'
import DeviceParams from "V/facilitiesEquipment/elevator/elevatorArchives/timeMonitoring/DeviceParams";
import {getTemperatureByAreaname} from "A/bigdata";
import {setPlayerParams} from "A/xtpz";
import {getCache} from "U/index";
import {getLiftVideoRecordingUrl} from "../../../../../api/wlw";
export default {
  components: {
    MonitoringBaseInfo,
    MonitoringEvent,
    MonitoringMark,
    DeviceParams,
    LivePlayer
  },
  props: ['liftcode', 'liftnum', 'address', 'deviceinfo','terminaltype','devicenum'],
  data() {
    return {
      processVisible:false,
      playtype:0,
      vlc:null,
      flvPlayer:null,
      videoUrl:'http://',
      videoElement:'',
      eventrecordid:'',
      start_time:'',
      end_time:'',
      playback_ssrc:'',
      play_flag:1,
      state:'',
      serviceModeMap: {
        '0': '停止服务',
        '1': '正常运行',
        '2': '检修',
        '3': '消防返回',
        '4': '消防员运行',
        '5': '应急电源运行',
        '6': '地震模式',
        '7': '未知',
      },
      serviceModeColors: {
        '0': '#f5222d',
        '1': '#52c41a',
        '2': '#fa8c16',
        '3': '#fa8c16',
        '4': '#fa8c16',
        '5': '#f5222d',
        '6': '#f5222d',
        '7': '#f5222d',
      },
      statusColors: {
        '00': '#f5222d',
        '01': '#52c41a',
      },
      carStatusMap: {
        '00': '停止',
        '01': '运行',
      },
      carDirectionMap: {
        '00': '无方向',
        '01': '上行',
        '02': '下行',
      },
      doorZoneMap: {
        '00': '轿厢在非开锁区域',
        '01': '轿厢在开锁区域',
      },
      doorStatusMap: {
        // '00': '未关门到位',
        '00': '开门',
        '01': '关门到位',
      },
      timeRange: null,
      cardsData: [],
      activeKey: 'tab1',
      playbtn: 'start',
      temperature:'',
      text:'',
      playerSize: {
        width: 482,
        height: 340
      },
      playbackVisible: false,
      markFlag: false,
      markVisible: false,
      snapShotImageSrc: '',
      markFormDatas: {
        type1: '00',
        type2: '',
      },
      markFormRules: {
        type1: [{required: true, message: '请选择标注类型'}],
        type2: [{required: true, message: '请选择AI参数'}],
      },
      markpointImageVisible: false,
      resultData:'',
      m:0,

      //视频截图与录像参数
      recordVisible:true,
      recorder:null,
      recordElement:{
        srcObject:null,
        src:'',
      },
      repeat:null,
      repeatBlink:null,
      RECArea:'',
      timer:null,
      mediaParts:[],
    }
  },
  destroyed() {
    // if(this.playback_ssrc){
    //   this.stopPlayback()
    // }
    if(this.flvPlayer){
      this.flv_destroy()
    }
  },
  computed: {
    ...mapState({
      socketMessage: state => state.socketMessage
    })
  },
  watch: {
    socketMessage(val) {
      if (val) {
        if (val.type == '1') {
          this.getCardsData(val.data);
        }
      }
    },
    'processVisible': function () {
      this.initVideoProcess(this.start_time ? this.start_time : Math.ceil(new Date().getTime() / 1000));
    },
  },
  mounted(){
    if(this.playtype==1){
      this.vlc=document.getElementById("vlc");
    }else{
      // let _this = this;
      // window.onbeforeunload = function (e) {
      //   // _this.stopPlayback()
      //   if(_this.flvPlayer){
      //     _this.flv_destroy()
      //   }
      //   if (_this.$route.name == "timeMonitoring") {
      //     e = e || window.event;
      //     // 兼容IE8和Firefox 4之前的版本
      //     if (e) {
      //       e.returnValue = '关闭提示';
      //     }
      //     // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
      //     return '关闭提示';
      //   } else {
      //     window.onbeforeunload = null
      //   }
      // }
    }
    this.preview();
  },
  created() {
    this.init();
    this.getPlayerSize();
    this.getTemp();
  },
  methods: {
    init(){
      let logininfo = getCache('logininfo', true);
      if(logininfo) {
        this.playtype=logininfo.playtype
      }
    },
    initPlayer(){
      if (flvjs.isSupported()) {
        this.createPlayer(this.videoUrl)
      }
    },
    preview(){
      if(this.terminaltype!=10){
        if(this.flvPlayer){
          this.flv_destroy()
        }
        // if(this.playback_ssrc){
        //   this.stopPlayback()
        // }
        // this.videoUrl=""
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              params: {
                methord: "PUT",
                url:"/video",
                body: {
                  ssrc:this.devicenum+'-0',
                  playtype:1,
                  start_time:this.start_time,
                  end_time:this.end_time,
                }
              }
            }
          ]
        }
        this.$message.success("正在获取视频，请稍候。")
        let that=this;
        setPlayerParams(params).then(res=>{
          if(res&&res.returncode=='0') {
            this.processVisible=!this.processVisible
            if(that.playtype==1){
              that.videoUrl = res.url
              that.itemId= that.vlc.playlist.add(that.videoUrl);
              that.vlc.playlist.playItem(that.itemId);
            }else {
              that.videoUrl = res.url
              that.$message.success("视频正在缓冲，请稍候。")
              // that.initPlayer()
            }
          }else{
            this.$message.error("网络出现问题，请检查网络或者刷新重试")
          }
        }).catch(err => {
          this.$message.error("网络出现问题，请检查网络或者刷新重试")
        })
      }
    },
    playback(){
      // if(this.playback_ssrc){
      //   this.stopPlayback()
      // }
      if(this.flvPlayer){
        this.flv_destroy()
      }
      if(this.timeRange && this.timeRange.length) {
        this.start_time = this.timeRange[0].format('X');
        this.end_time = this.timeRange[1].format('X');
      }
      this.$message.success("正在获取视频，请稍候。")
      // this.videoUrl=""
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            params: {
              methord: "PUT",
              url:"/video",
              body: {
                ssrc:this.devicenum,
                playtype:2,
                start_time:this.start_time,
                end_time:this.end_time,
              }
            }
          }
        ]
      }
      let that=this;
      setPlayerParams(params).then(res=>{
        if(res&&res.returncode=='0') {
          this.processVisible=!this.processVisible;
          this.playback_ssrc=res.ssrc
          if(that.playtype==1){
            that.videoUrl = res.url
            that.itemId= that.vlc.playlist.add(that.videoUrl);
            that.vlc.playlist.playItem(that.itemId);
          }else{
            that.videoUrl = res.url
            that.$message.success("视频正在缓冲，请稍候。")
            // that.initPlayer()
          }
        }else if(res&&res.returncode=='3'){
          getLiftVideoRecordingUrl({eventrecordid: this.eventrecordid}).then(res => {
            if (res && res.returncode == '0' && res.videoPath) {
              this.videoUrl=res.videoPath
              this.$message.success("视频正在缓冲，请稍候。")
              // if (flvjs.isSupported()) {
              //   this.videoElement = document.getElementById('videoId');
              //   this.flvPlayer = flvjs.createPlayer({
              //     type: 'mp4',
              //     cors: true, // 开启跨域访问
              //     url: res.videoPath,
              //   });
              //   this.$message.success("视频正在缓冲，请稍候。")
              //   this.flvPlayer.attachMediaElement(this.videoElement);
              //   this.flvPlayer.load();
              //   this.flvPlayer.play();
              // }
            }else{
              this.$message.error("录像文件不存在或者该时刻录像文件失败")
            }
          })
        }else{
          this.$message.error("网络出现问题，请检查网络或者刷新重试")
        }
      }).catch(err => {
        this.$message.error("网络出现问题，请检查网络或者刷新重试")
      })
    },
    createPlayer(url){
      let that = this
      if (this.flvPlayer) {
        this.flv_destroy()
      }
      if (flvjs.isSupported()) {
        this.videoElement = document.getElementById('videoId');
        this.flvPlayer = flvjs.createPlayer({
          isLive: false,
          hasAudio: false,
          type: 'flv',
          url: url
        });
        this.flvPlayer.attachMediaElement(this.videoElement);
        this.flvPlayer.load();
        this.$message.success("视频正在缓冲，请稍候。")
        this.flvPlayer.play();

        this.flvPlayer.on("statistics_info", function (res) {
          if (that.lastDecodedFrame == 0) {
            that.lastDecodedFrame = res.decodedFrames;
            return;
          }
          if (that.lastDecodedFrame != res.decodedFrames) {
            that.lastDecodedFrame = res.decodedFrames;
          } else {
            that.lastDecodedFrame = 0;
            if (that.flvPlayer) {
              that.flvPlayer.pause();
              that.flvPlayer.unload();
              that.flvPlayer.detachMediaElement();
              that.flvPlayer.destroy();
              that.flvPlayer= null;
              that.reloadPlayer(url)
            }
          }
        });
        this.flvPlayer.on(flvjs.Events.ERROR, (errorType, errorDetail, errorInfo) => {
          // console.log("errorType:", errorType);
          // console.log("errorDetail:", errorDetail);
          // console.log("errorInfo:", errorInfo);
          //视频出错后销毁重新创建
          if (that.flvPlayer) {
            that.flvPlayer.pause();
            that.flvPlayer.unload();
            that.flvPlayer.detachMediaElement();
            that.flvPlayer.destroy();
            that.flvPlayer= null;
            that.reloadPlayer(url)
          }
        });
      }
    },
    reloadPlayer(url){
      console.log("尝试断线重连中。。。")
      if (flvjs.isSupported()) {
        this.videoElement = document.getElementById('videoId');
        this.flvPlayer = flvjs.createPlayer(
            {
              type: 'flv',
              isLive: false,
              hasAudio: false,
              cors: true, // 开启跨域访问
              url: url,
            }, {
              autoCleanupSourceBuffer: false,//对SourceBuffer进行自动清理
              autoCleanupMaxBackwardDuration: 12,//    当向后缓冲区持续时间超过此值（以秒为单位）时，请对SourceBuffer进行自动清理
              autoCleanupMinBackwardDuration: 8,//指示进行自动清除时为反向缓冲区保留的持续时间（以秒为单位）。
              enableStashBuffer: false, //关闭IO隐藏缓冲区
              isLive: false,
              lazyLoad: false,
            });
        this.flvPlayer.attachMediaElement(this.videoElement);
        if (url !== "" && url !== null) {
          this.flvPlayer.load();
          this.flvPlayer.play();
        }
      }
    },
    stopPlayback(){
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            params: {
              methord: "PUT",
              url:"/video",
              body: {
                ssrc:this.playback_ssrc,
                playtype:3,
                start_time:this.start_time,
                end_time:this.end_time,
              }
            }
          }
        ]
      }
      setPlayerParams(params).then(res=>{
        if(res&&res.returncode=='0') {
          this.playbtn="start"
          this.playback_ssrc=res.ssrc
          this.videoUrl = res.url
        }
      })
    },
    flv_destroy() {
      console.log("注销播放器组件")
      this.flvPlayer.pause()
      this.flvPlayer.unload()
      this.flvPlayer.detachMediaElement()
      this.flvPlayer.destroy()
      this.flvPlayer = null
    },
    change(msg){
      this.timeRange=[];
      this.eventrecordid=msg.eventrecordid
      this.devicenum = msg.devicenum;
      this.start_time = moment(msg.starttime,'YYYYMMDDHHmmss').unix()-60;
      this.end_time = moment(msg.endtime,'YYYYMMDDHHmmss').unix()+180;
      this.playback()
    },
    result(res){
      this.resultData=res
    },
    start_playback(){
      this.playbtn="stop"
      this.playbackVisible = false;
      this.playback()
    },
    cancelPlayBack() {
      this.playbackVisible = false;
    },
    getCardsData(data) {
      this.cardsData = data;
    },
    getTemp(){
      let params = {
        devicenum:this.devicenum
      }
      getTemperatureByAreaname(params).then(res=>{
        if(res!=null&&res!=''){
          this.temperature=res.temp.temperature
          this.text=res.temp.text
        }
      })
    },
    getPlayerSize() {
      if(this.$route.params.activeKey!=''&&this.$route.params.activeKey!=null){
        this.activeKey=this.$route.params.activeKey
      }
      let height = Math.min(window.innerHeight - 330, 440);
      let width = parseInt(height*1.77, 10);
      this.playerSize = {
        width,
        height
      }
    },
    startPlayPreview() {
      this.$refs.videoControl.playPreview();
      this.playbtn = 'stop';
    },
    stopPlay() {
      this.$refs.videoControl.stopPlayer();
      this.playbtn = 'start';
    },
    hidePlayer() {
      this.playbackVisible=true;
    },
    snapShot() {
      // if(this.markFlag) {
      //   return;
      // }
      this.markFlag = true;
      this.snapShotImageSrc = '';
      this.markVisible=true;
      this.cutPicture(false)
    },
    cutPicture(flag){
      let self=this;
      let v = document.querySelector("video");
      let canvas = document.getElementById('myCanvas')
      let ctx = canvas.getContext('2d');
      ctx.drawImage(v, 0, 0, 1029, 600);
      v.setAttribute('crossOrigin', 'anonymous');
      ctx.drawImage(v, 0, 0, 1029, 600);
      let data = canvas.toDataURL('image/jpeg');
      this.snapShotImageSrc = data
      if(flag){
        this.downloadFile(data)
      }
    },
    //下载图片
    downloadFile(content) {
      let time = moment(new Date()).format("YYYYMMDDHHmmss");
      let timeName = time.substring(0,4)+"年"+time.substring(4,6)+"月"+time.substring(6,8)+"日"+time.substring(8,10)+"时"+time.substring(10,12)+"分"+time.substring(12,14)+"秒"
      // const name = this.monitorname+"-"+new Date().toISOString().slice(0,19).replace('T',' ').replace(' ','_').replace(/:/g,'-')+".jpg";
      const name = this.address+"-"+timeName+".jpg";
      let aLink = document.createElement('a');
      let blob = this.base64ToBlob(content); //new Blob([content]);
      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = name;
      aLink.href = URL.createObjectURL(blob);
      // aLink.dispatchEvent(evt);
      aLink.click()
    },
    //base64转blob
    base64ToBlob(code) {
      let parts = code.split(';base64,');
      let contentType = parts[0].split(':')[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;

      let uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], {type: contentType});
    },
    snapShotResult(res) {
      if(res == 'success') {
        this.markVisible=true;
      }else {
        this.$message.error('抓图失败');
      }
      this.markFlag = false;
    },
    snapShotImage(src) {
      this.snapShotImageSrc = src;
    },
    selectMakrParams() {
      // this.$refs.videoControl.hidePlayer();
      this.markVisible = true;
    },
    startMark() {
       this.markVisible = false;
       this.markpointImageVisible = true;
    },
    cancelMark() {
      this.$refs.markForm.resetFields();
      this.markVisible = false;
      // this.$refs.videoControl.showPlayer();
    },
    closeMarkpoint() {
      this.$refs.markForm.resetFields();
      this.markpointImageVisible = false;
    },
    initVideoProcess(starttime){
      var that=this;
      that.m++;
      //进度条起始时间
      var recordStartTime = starttime;
      var myPlayTimer =  new hwxPBSTimer("processCanvas", recordStartTime, playRecordCallBack,that.m);
      var timePeriodsArr = new Array();
      timePeriodsArr.length = 0;
      timePeriodsArr.push(starttime);
      timePeriodsArr.push(starttime+1800);

      //录像时间段
      var recordedStatusTbls = new Array();
      recordedStatusTbls.length = 0;
      // recordedStatusTbls.push(new Array(1641869789, 1641880000));
      // recordedStatusTbls.push(new Array(1473724800, 1473739200));
      // recordedStatusTbls.push(new Array(1473757200, 1473782400));

      let recordStatus = new hwxRecordStatus(timePeriodsArr, recordedStatusTbls);

      myPlayTimer.addRecordStatus(recordStatus);

      function playRecordCallBack(cmd, args) {
        if (cmd == hwxPBSTimerCbCmd.ReqPlayTime)
            //alert("ҥ؅"+args[0]);
          ;
        else if (cmd == hwxPBSTimerCbCmd.ReqStatusTbls)
          ; //alert("өѯ¼б״̬"+args[0]+","+args[1]);
      }

      function updateCanvas() {
        myPlayTimer.CanvasUpdate();
      }

      myPlayTimer.run();
      // window.onload = function () {
      //   myPlayTimer.run();
      // }


      //定义回掉函数命令码
      if (typeof hwxPBSTimerCbCmd == "undefined") {
        var hwxPBSTimerCbCmd = {};
        hwxPBSTimerCbCmd.ReqPlayTime = 0;   //重新定位播放起始位置
        hwxPBSTimerCbCmd.ReqStatusTbls = 1; //重新请求录像状态表
      }
      /*
      录像状态类
      timePeriods： 录像查询区间
      recordedTimeTbls： 录像的时间表
      */
      function hwxRecordStatus(timePeriodsArr, recordedTimeTbls) {
        this.ptimePeriodsArr = new Array();

        if (timePeriodsArr != undefined) {
          for (var i = 0; i < timePeriodsArr.length; i++) {
            this.ptimePeriodsArr.push(timePeriodsArr[i]);
          }
        }

        this.precordedTimeTbls = new Array();

        if (recordedTimeTbls != undefined) {
          for (var i = 0; i < recordedTimeTbls.length; i++) {
            this.precordedTimeTbls.push(recordedTimeTbls[i]);
          }
        }

      }
      function hwxPBSTimer(canvasId, playTime, hwxPBSTimerCallback,m) {
        var parentCanvasId = canvasId;
        var pCanvasId = parentCanvasId+"_"+m;
        var mouseCanvasId = parentCanvasId+"_"+m+1;
        var curPlayTime = playTime;
        var pHwxPBSTimerCallback = hwxPBSTimerCallback;

        //回掉参数数组
        var callbackArgsArr = new Array();
        //录像状态数组
        var recordedStatusArr = new Array();


        //鼠标左键按下标志
        var mouseDownFlag = 0;
        //鼠标移动标志
        var mouseDownMoveStep = 0;

        //鼠标当前位置
        var mousePosX = 0;
        var mousePosY = 0;
        //鼠标经过标志
        var mouseOverFlag = 0;
        //鼠标单击事件
        var mouseclickFlag = 0;
        var mouseZoomDownFlag = 0;
        //鼠标双击事件标志
        var mousedblclickFlag = 0;
        //鼠标移动方向1 向右 减； 0 向左 加
        var mouseMoveDir = 0;

        //时间字符长占用的像素（经验值）
        var timeStrPixLen = 85;
        //鼠标左键按下时播放时间
        var mouseDownPlayTime = 0;
        var mouseUpPlayTime = 0;

        /*
        60               5 秒钟一个刻度值 (12个)
        1800  30分钟     5 分钟一个刻度值  （6个）
        3600  1小时      10 分钟一个刻度值  （6个）
        86400 24小时     2 小时一个刻度值  （12个）
        */

        //时间宽度表单位是秒
        var timeWidthTbls = new Array(60, 1800, 3600, 86400);
        //时间宽度说明表
        var timeWidthTextTbls = new Array("范围: 1分钟; 单位: 秒", "范围: 30分钟; 单位: 分钟", "范围: 1小时; 单位: 分钟", "范围: 1天; 单位: 小时");
        //拖拽移动步长单位是秒
        var timeWidthStepTbls = new Array(1, 15, 60, 1800);
        var timeWidthMarkTbls = new Array(10, 60, 900, 10800);
        //当前使用的时间宽度索引
        var timeWidthTblIndex = 3;
        var timeWidthTblNum = timeWidthTbls.length;

        //主定时器
        var mainTimer = null;
        var ini_step = 0;
        do{
          //获取浏览器信息
          var browserMatch = getBrowserInfo();

          //IE 11.0  8.0
          //调试信息
          var dbgStr = browserMatch.browser+browserMatch.version;


          createCanvasDiv();
          createMouseEventDiv();

          //获取画布
          var myCanvas = document.getElementById(pCanvasId);
          if(myCanvas==null || myCanvas==undefined)
          {
            alert("获取画布" + pCanvasId + "失败！！");
            ini_step++;
            break;
          }
          var myMouseCanvas = document.getElementById(mouseCanvasId);
          if(myMouseCanvas==null || myMouseCanvas==undefined)
          {
            alert("获取鼠标DIV" + mouseCanvasId + "失败！！");
            ini_step++;
            break;
          }
          var myPen = new jsGraphics(pCanvasId);
          if(myPen==null || myPen==undefined)
          {
            alert("创建DIV 画笔失败！！");
            ini_step++;
            break;
          }
          //获取div 宽高
          //var canvasWidth = myCanvas.offsetWidth;
          /*
        说明 offsetWidth 存在为0 的情况，但调试模式下不存原因尚不清楚
      */
          var canvasWidth = myCanvas.clientWidth;
          if( canvasWidth==undefined || canvasWidth==null || canvasWidth==0)
          {
            alert("获取画布宽失败"+canvasWidth);
            ini_step++;
            break;
          }
          //var canvasHeight = myCanvas.offsetHeight;
          var canvasHeight = myCanvas.clientHeight;
          if(canvasHeight==undefined || canvasHeight==null || canvasHeight==0)
          {
            alert("获取画布高失败");
            ini_step++;
            break;
          }


          //录像状态条信息
          var vStatusX = 0;
          var vStatusY = parseInt(canvasHeight / 5) * 4;
          var vStatusWidth = canvasWidth;
          var vStatusHeight = parseInt(canvasHeight / 5) * 1;

        }while(0);

        function createMouseEventDiv() {
          do{
            // console.log(parentCanvasId)
            var parentCanvas = document.getElementById(parentCanvasId);
            // console.log(parentCanvas)
            if(parentCanvas==null || parentCanvas==undefined)
            {
              alert("getElementById " + parentCanvasId + "错误！" );
              ini_step++;
              break;
            }

            var parentWidth = parentCanvas.offsetWidth;
            if(parentWidth==null || parentWidth==undefined)
            {
              alert("获取画布宽失败");
              ini_step++;
              break;
            }
            var parentHeight = parentCanvas.offsetHeight;
            if(parentHeight==null || parentHeight==undefined)
            {
              alert("获取画布高失败");
              ini_step++;
              break;
            }
            var filterStr ='';
            if(browserMatch.browser=='IE' && browserMatch.version <=9.0)
            {
              filterStr = 'filter:alpha(opacity=0);';            //IE9.0以下 版本的区别  支持滤镜
            }
            else
            {
              filterStr = 'opacity: 0;';                          //IE10.0以上 版本的区别
            }
            var htm = '<div id="' + mouseCanvasId + '" style="position:absolute;' +
                'width:' + parentWidth + 'px;' +
                'height:' + parentHeight + 'px;' +
                'background-color:#363636;' +
                filterStr +
                '"><\/div>';
            //alert(htm);
            parentCanvas.insertAdjacentHTML("beforeEnd", htm);
          }while(0);
        }

        function createCanvasDiv () {
          do{
            var parentCanvas = document.getElementById(parentCanvasId);
            if(parentCanvas==null || parentCanvas==undefined)
            {
              alert("getElementById " + parentCanvasId + "错误！" );
              ini_step++;
              break;
            }

            var parentWidth = parentCanvas.offsetWidth;
            if(parentWidth==null || parentWidth==undefined)
            {
              alert("获取画布宽失败");
              ini_step++;
              break;
            }
            var parentHeight = parentCanvas.offsetHeight;
            if(parentHeight==null || parentHeight==undefined)
            {
              alert("获取画布高失败");
              ini_step++;
              break;
            }
            var htm = '<div id="' + pCanvasId + '" style="position:absolute;' +
                'width:' + parentWidth + 'px;' +
                'height:' + parentHeight + 'px;' +
                'background-color:#363636;"' +
                '><\/div>';
            //alert(htm);
            parentCanvas.insertAdjacentHTML("beforeEnd", htm);
          }while(0);
        }

        //添加录像状态
        this.addRecordStatus = function (status) {
          if (status == undefined)
            return;
          recordedStatusArr.push(status);
        }
        //清空录像状态
        this.cleanRecordStatus = function () {
          recordedStatusArr.length = 0;
        }
        //更新当前播放时间
        this.updatePlayTimeFunc = function (playTime) {
          if (playTime == undefined)
            return;
          if (mouseDownFlag)
            return;
          curPlayTime = playTime;
        };
        this.cleanPSBTimerFunc = function () {
          clearInterval(this.updatePBSTimerId);
        };
        //设置新的起始时间播放
        function setNewPlayStartTimeCallback() {
          callbackArgsArr.length = 0;
          callbackArgsArr.push(curPlayTime);
          pHwxPBSTimerCallback(hwxPBSTimerCbCmd.ReqPlayTime, callbackArgsArr);
        }


        function isInTimePeriods(time) {
          if (time == undefined)
            return;
          var pNum = 0;
          for (var j = 0; j < recordedStatusArr.length; j++) {
            //大区间
            var recStatus = recordedStatusArr[j];
            //
            if (time >= recStatus.ptimePeriodsArr[0] && time <= recStatus.ptimePeriodsArr[1]) {
              pNum++;
            }
          }

          if (pNum != 0)
            return true;
          else
            return false

        }
        //检查是否超出当前已有的时间区域
        function checkTimePeriods() {
          //时间宽度值 1min ,30min , 1h, 24h
          var timeWidth = timeWidthTbls[timeWidthTblIndex];
          var startTime = curPlayTime - parseInt(timeWidth / 2);
          var endTime = curPlayTime + parseInt(timeWidth / 2);

          var arrSize = recordedStatusArr.length;

          var sNum = 0;
          var eNum = 0;
          var sTime = 0;
          var eTime = 0;

          if (isInTimePeriods(startTime) == false) {
            var date = new Date(startTime * 1000);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);

            sTime = parseInt(Date.parse(date) / 1000);
          }

          if (isInTimePeriods(endTime) == false) {
            var date = new Date(endTime * 1000);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);

            eTime = parseInt(Date.parse(date) / 1000);
          }

          if (sTime != 0)
            requestNewTimeSpaceStatus(sTime, sTime + 84600)

          if (sTime != eTime) {
            if (eTime != 0)
              requestNewTimeSpaceStatus(eTime, eTime + 84600)
          }
          return;
        }

        function requestNewTimeSpaceStatus(startTime, endTime) {
          if (startTime == undefined || endTime == undefined)
            return;
          callbackArgsArr.length = 0;
          callbackArgsArr.push(startTime);
          callbackArgsArr.push(endTime);

          pHwxPBSTimerCallback(hwxPBSTimerCbCmd.ReqStatusTbls, callbackArgsArr);
        }

        function getBrowserInfo()
        {
          var ua = navigator.userAgent.toLowerCase() ;
          var rMsie = /(msie\s|trident.*rv:)([\w.]+)/,
              rFirefox = /(firefox)\/([\w.]+)/,
              rOpera = /(opera).+version\/([\w.]+)/,
              rChrome = /(chrome)\/([\w.]+)/,
              rSafari = /version\/([\w.]+).*(safari)/;
          var browser;
          var version;


          var match = rMsie.exec(ua);
          if(match != null){
            browser = "IE";
            version = match[2] || "0";
            return {
              browser : "IE", version : match[2] || "0" };
          }
          var match = rFirefox.exec(ua);
          if (match != null) {
            return { browser : match[1] || "", version : match[2] || "0" };
          }
          var match = rOpera.exec(ua);
          if (match != null) {
            return { browser : match[1] || "", version : match[2] || "0" };
          }
          var match = rChrome.exec(ua);
          if (match != null) {
            return { browser : match[1] || "", version : match[2] || "0" };
          }
          var match = rSafari.exec(ua);
          if (match != null) {
            return { browser : match[2] || "", version : match[1] || "0" };
          }
          if (match != null) {
            return { browser : "", version : "0" };
          }
        }

        /*
            刷新画布
        */
        function CanvasUpdate () {

          /*
          if (mouseDownFlag)
              pause(1000);*/

          myPen.clear();
          drawTimeStrText();
          drawScaleValue();
          drawRecordedStatus();
          drawZoomInBtn();
          drawZoomOutBtn();
        }

        //启动定时器
        this.run = function () {
          if (mainTimer == undefined || mainTimer==null)
            mainTimer = setInterval(CanvasUpdate, 100);
        }

        function prun () {
          if (mainTimer == undefined || mainTimer == null)
            mainTimer = setInterval(CanvasUpdate, 100);
        }

        function stop() {
          if (mainTimer != undefined && mainTimer != null) {
            clearInterval(mainTimer);
            mainTimer = null;
          }
        }

        function pause(ms) {
          if (ms == undefined)
            ms = 1000;
          stop();
          setTimeout(prun, ms);
        }


        //文本信息
        function drawTimeStrText() {
          var maxHeight = canvasHeight;
          var maxWidth = canvasWidth;

          //时间宽度值 1min ,30min , 1h, 24h
          var timeWidth = timeWidthTbls[timeWidthTblIndex];

          var startTime = curPlayTime - parseInt(timeWidth / 2);
          var endTime = curPlayTime + parseInt(timeWidth / 2);


          //鼠标单击事件
          if (mouseclickFlag) {
            mouseclickFlag = 0;
            if (mousePosY >= maxHeight / 2) {
              curPlayTime = startTime + parseInt(timeWidth * mousePosX / maxWidth);
              that.start_time=curPlayTime
              that.end_time=curPlayTime+1800
              // console.log(that.start_time)
              // console.log(that.end_time)
              that.playback()

              startTime = curPlayTime - parseInt(timeWidth / 2);
              endTime = curPlayTime + parseInt(timeWidth / 2);
              setNewPlayStartTimeCallback();
            }
          }

          //鼠标双击事件
          if (mousedblclickFlag) {
            mousedblclickFlag = 0;
            if (mousePosY >= maxHeight / 2) {
              curPlayTime = startTime + parseInt(timeWidth * mousePosX / maxWidth);
              that.start_time=curPlayTime
              that.end_time=curPlayTime+1800
              // console.log(that.start_time)
              // console.log(that.end_time)
              that.playback()

              startTime = curPlayTime - parseInt(timeWidth / 2);
              endTime = curPlayTime + parseInt(timeWidth / 2);
              setNewPlayStartTimeCallback();
            }
          }


          //文字示例
          //播放时间

          var midTimestrPixLen = 196;

          var date = new Date(curPlayTime * 1000);
          var Y = date.getFullYear() + '-';
          var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
          var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';


          var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
          var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
          var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());

          var timeStr = Y + M + D + h + m + s;

          myPen.setColor("#FFF68F");
          myPen.setFont('verdana,geneva,helvetica,sans-serif',16, Font.PLAIN);
          myPen.drawString(timeStr, maxWidth / 2 - midTimestrPixLen / 2, 0);
          myPen.paint();

          //画中间播放的刻度线
          myPen.setFont('verdana,geneva,helvetica,sans-serif', 32, Font.PLAIN);

          myPen.setStroke(2);
          myPen.setColor("#FCFCFC");
          myPen.drawLine(maxWidth / 2, maxHeight / 5, maxWidth / 2, maxHeight);
          myPen.paint();

          myPen.setStroke(1);

          myPen.setFont('verdana,geneva,helvetica,sans-serif', String.fromCharCode(0x31, 0x32, 0x70, 0x78), Font.PLAIN);


          //当前的时间范围
          myPen.setColor("#FCFCFC");
          myPen.drawString("提示：单击下方进度条可进行回放", maxWidth / 20 * 1, 35);
          myPen.setColor("#ffc901");
          myPen.drawString("精度调节：", maxWidth / 5 * 3.6, 12);
          myPen.drawString(timeWidthTextTbls[timeWidthTblIndex], maxWidth / 6 * 1, 20);


          //叠加调试信息
          // if(dbgStr != undefined && dbgStr.length >0)
          // {
          //     myPen.setColor("#FCFCFC");
          //     myPen.drawString(dbgStr, 0, 20);
          // }

          /*
          var timeStr = timeWidthTextTbls[timeWidthTblIndex];
          context.strokeStyle = "#FCFCFC";
          context.lineWidth = 1.4;
          context.font = "smaller sans-serif";
          context.strokeText(timeStr, maxWidth / 5 * 3, 20);
          */

          timeStrPixLen = 150;

          //当前鼠标位置，对应的时间刻度
          if (mouseOverFlag == 1 && mousePosY >= maxHeight /2) {

            var date = new Date((startTime + parseInt(timeWidth * mousePosX / maxWidth)) * 1000);
            Y = date.getFullYear() + '-';
            M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';

            h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());

            var timeStr = Y + M + D + h + m + s;
            var x = mousePosX;

            if (mousePosX + timeStrPixLen > maxWidth)
              x = maxWidth - timeStrPixLen;

            myPen.setColor("#FCFCFC");
            myPen.drawString(timeStr, x, maxHeight/2);
          }
        }

        //画 录像状态条
        function drawRecordedStatus() {
          var rectX = 0;
          var rectW = 0;
          //时间宽度值 1min ,30min , 1h, 24h
          var timeWidth = timeWidthTbls[timeWidthTblIndex];
          var startTime = curPlayTime - parseInt(timeWidth / 2);
          var endTime = curPlayTime + parseInt(timeWidth / 2);
          var maxHeight = canvasHeight;
          var maxWidth = canvasWidth;

          //当前显示区间内录像时间表
          var curSenceTbls = new Array();
          var tblsNum = 0;
          //计算录像区域
          var arrSize = recordedStatusArr.length;

          for (var j = 0; j < arrSize; j++) {
            //大区间
            var recStatus = recordedStatusArr[j];
            if ((startTime >= recStatus.ptimePeriodsArr[0] && startTime <= recStatus.ptimePeriodsArr[1]) || (endTime >= recStatus.ptimePeriodsArr[0] && endTime <= recStatus.ptimePeriodsArr[1])) {
              var recordedTimeTbls = recStatus.precordedTimeTbls;
              for (var i = 0; i < recordedTimeTbls.length; i++) {
                if (recordedTimeTbls[i][0] < startTime) {
                  if (recordedTimeTbls[i][1] > startTime) {
                    if (recordedTimeTbls[i][1] <= endTime) {
                      curSenceTbls[tblsNum] = new Array(startTime, recordedTimeTbls[i][1]);
                      tblsNum++;
                    }
                    else {
                      curSenceTbls[tblsNum] = new Array(startTime, endTime);
                      tblsNum++;
                    }
                  }
                }
                else if (recordedTimeTbls[i][0] >= startTime && recordedTimeTbls[i][0] < endTime) {
                  if (recordedTimeTbls[i][1] <= endTime) {
                    curSenceTbls[tblsNum] = new Array(recordedTimeTbls[i][0], recordedTimeTbls[i][1]);
                    //alert(recordStatusTbls[i][0]+"--"+startTime);
                    tblsNum++;
                  }
                  else {
                    curSenceTbls[tblsNum] = new Array(recordedTimeTbls[i][0], endTime);
                    //alert(recordStatusTbls[i][0]+"--"+recordStatusTbls[i][1]);
                    tblsNum++;
                  }
                }
                else {

                }
              }
            }
          }
          //没有录像
          // if (tblsNum <= 0) {
          //     myPen.setColor("#00EC00");
          //     myPen.drawString("没有录像", maxWidth / 2, maxHeight / 2);
          //
          //     myPen.paint();
          // }
          for (var i = 0; i < tblsNum; i++) {
            vStatusX = (curSenceTbls[i][0] - startTime) / timeWidth * maxWidth;
            vStatusWidth = (curSenceTbls[i][1] - curSenceTbls[i][0]) / timeWidth * maxWidth;
            myPen.setColor("#00EC00");

            /*
            myPen.drawLine(vStatusX, vStatusY, vStatusX + vStatusWidth, vStatusY);
            myPen.drawLine(vStatusX, vStatusY+1, vStatusX + vStatusWidth, vStatusY+1);
            myPen.drawLine(vStatusX, vStatusY+2, vStatusX + vStatusWidth, vStatusY+2);
            myPen.drawLine(vStatusX, vStatusY+3, vStatusX + vStatusWidth, vStatusY+3);
            myPen.drawLine(vStatusX, vStatusY + 4, vStatusX + vStatusWidth, vStatusY + 4);
            */
            myPen.fillRect(vStatusX, vStatusY, vStatusWidth, vStatusHeight-1);

            myPen.paint();
          }
        }

        //画刻度
        function drawScaleValue() {
          //时间宽度值 1min ,30min , 1h, 24h
          var timeWidth = timeWidthTbls[timeWidthTblIndex];
          var startTime = curPlayTime - parseInt(timeWidth / 2);
          var endTime = curPlayTime + parseInt(timeWidth / 2);
          var maxHeight = canvasHeight;
          var maxWidth = canvasWidth;

          //var timeWidthTbls = new Array(60,1800,3600,86400);
          /*
        60               5 秒钟一个刻度值 (12个)
          1800  30分钟     5 分钟一个刻度值  （6个）
        3600  1小时      10 分钟一个刻度值  （6个）
        86400 24小时     2 小时一个刻度值  （12个）
      */

          var timeWidth = timeWidthTbls[timeWidthTblIndex];
          var curScale = 0;
          var scaleStrPixlen = 18; //经验值
          var lx,ly;

          switch (timeWidth) {
            case 60:
              var date = new Date(startTime * 1000);
              var startSecond = date.getSeconds();
              for (var i = 0; i < 60; i++) {
                curScale = startSecond + i;
                if (curScale >= 60)
                  curScale = curScale - 60;
                if (curScale % 5 == 0) {
                  //画 刻度值
                  myPen.setColor("#ADADAD");
                  if((i * maxWidth / 60 - scaleStrPixlen / 2) >0)
                    myPen.drawString(curScale, i * maxWidth / 60 - scaleStrPixlen / 2, maxHeight /5* 3);
                  myPen.paint();

                  //画 刻度线
                  myPen.setColor("#ADADAD");
                  myPen.drawLine(i * maxWidth / 60, maxHeight / 5 * 4 - 3, i * maxWidth / 60, maxHeight);
                  myPen.paint();

                }
              }
              break;
            case 1800:
              var date = new Date(startTime * 1000);
              var startHour = date.getHours();
              var startMin = date.getMinutes();
              var startSec = date.getSeconds();

              var curSecOffset = startSec;  //重点

              for (var i = 0; i < 30; i++) {
                curScale = startMin + i;
                if (curScale >= 60)
                  curScale = curScale - 60;
                if (curScale % 5 == 0) {
                  lx=(i * 60 - curSecOffset) * maxWidth / 1800;
                  //画 刻度值
                  myPen.setColor("#ADADAD");
                  if(lx>=0)
                    myPen.drawString(curScale, lx - scaleStrPixlen / 2, maxHeight /5* 3);
                  myPen.paint();
                  //画 刻度线
                  myPen.setColor("#ADADAD");
                  if(lx>=0)
                    myPen.drawLine(lx, maxHeight / 5 * 4 - 3, lx, maxHeight);
                  myPen.paint();
                }
              }
              break;
            case 3600:
              var date = new Date(startTime * 1000);
              var startHour = date.getHours();
              var startMin = date.getMinutes();
              var startSec = date.getSeconds();

              var curSecOffset = startSec;
              //var curSecOffset = 60- startSec;
              //if(curSecOffset==60)
              //curSecOffset = 0;
              for (var i = 0; i < 60; i++) {
                curScale = startMin + i;
                if (curScale >= 60)
                  curScale = curScale - 60;
                if (curScale % 10 == 0) {
                  lx = (i * 60 - curSecOffset) * maxWidth / 3600;
                  //画 刻度值
                  myPen.setColor("#ADADAD");
                  if(lx>=0)
                    myPen.drawString(curScale, lx - scaleStrPixlen / 2, maxHeight / 5 *3);
                  myPen.paint();

                  //画 刻度线
                  myPen.setColor("#ADADAD");
                  if(lx>=0)
                    myPen.drawLine(lx, maxHeight / 5 * 4 - 3, lx, maxHeight);
                  myPen.paint();
                }
              }

              break;
            case 86400:
              var date = new Date(startTime * 1000);
              var startHour = date.getHours();
              var startMin = date.getMinutes();
              var startSec = date.getSeconds();
              //var curSecOffset = 3600 - startMin*60- startSec;
              var curSecOffset = startMin * 60 + startSec;
              //if(curSecOffset==3600)
              //curSecOffset = 0;
              for (var i = 0; i < 24; i++) {
                curScale = startHour + i;
                if (curScale >= 24)
                  curScale = curScale - 24;
                if (curScale % 2 == 0) {
                  lx = (i * 3600 - curSecOffset) * maxWidth / 86400;
                  //画 刻度值
                  myPen.setColor("#ADADAD");
                  if(lx>=0)
                    myPen.drawString(curScale, lx - scaleStrPixlen / 2, maxHeight / 5 * 3);
                  myPen.paint();

                  //画 刻度线
                  myPen.setColor("#ADADAD");
                  if(lx>=0)
                    myPen.drawLine(lx, maxHeight / 5 * 4 - 3, lx, maxHeight);
                  myPen.paint();
                }
              }
              break;
          }
        }
        //画缩放按钮+
        function drawZoomInBtn(){
          //时间宽度值 1min ,30min , 1h, 24h
          var timeWidth = timeWidthTbls[timeWidthTblIndex];
          var startTime = curPlayTime - parseInt(timeWidth / 2);
          var endTime = curPlayTime + parseInt(timeWidth / 2);
          var maxHeight = canvasHeight;
          var maxWidth = canvasWidth;

          //按钮中心坐标
          var BtnCx = maxWidth/10*9;
          var BtnCy = maxHeight/5;
          var BtnWidth = 20;
          var BtnHeight = 20;

          var BtnX = BtnCx - BtnWidth/2 ;
          var BtnY = BtnCy - BtnHeight/2 ;

          //画外圈
          myPen.setColor("#4F4F4F");
          myPen.fillRect(BtnX, BtnY, BtnWidth, BtnHeight);
          myPen.paint();

          var BtnWidth2 = 14;
          var BtnHeight2 = 14;
          var lineLen = 12;

          //动画效果
          if(mouseDownFlag==1){
            if(mousePosX>=BtnX && mousePosX <= BtnX+BtnWidth &&
                mousePosY >=BtnY && mousePosY <= BtnY +BtnHeight){
              BtnWidth2 = 20;
              BtnHeight2 = 20;
              lineLen = 14;

              if (timeWidthTblIndex <= 0) {
                timeWidthTblIndex = 0;
              }
              else {
                timeWidthTblIndex--;
              }
              //mouseclickFlag = 0;
            }
          }

          var BtnX2 = BtnCx - BtnWidth2/2 ;
          var BtnY2 = BtnCy - BtnHeight2/2 ;
          //画内圈
          myPen.setColor("#6C6C6C");
          myPen.fillRect(BtnX2, BtnY2, BtnWidth2, BtnHeight2);
          myPen.paint();

          var lhx1 = BtnCx - lineLen/2-1;
          var lhy1 = BtnCy-1;
          var lhx2 = BtnCx + lineLen/2-1;
          var lhy2 = BtnCy-1;

          //画横
          myPen.setStroke(2);
          myPen.setColor("#D0D0D0");
          myPen.drawLine(lhx1, lhy1, lhx2, lhy2);
          myPen.paint();

          //画竖
          var lsx1 = BtnCx-1;
          var lsy1 = BtnCy- lineLen/2-1;

          var lsx2 = BtnCx-1;
          var lsy2 = BtnCy + lineLen/2-1;

          myPen.setColor("#D0D0D0");
          myPen.drawLine(lsx1, lsy1, lsx2, lsy2);
          myPen.paint();

          myPen.setStroke(1);

        }
        //画缩放按钮-
        function drawZoomOutBtn(){
          //时间宽度值 1min ,30min , 1h, 24h
          //时间宽度值 1min ,30min , 1h, 24h
          var timeWidth = timeWidthTbls[timeWidthTblIndex];
          var startTime = curPlayTime - parseInt(timeWidth / 2);
          var endTime = curPlayTime + parseInt(timeWidth / 2);
          var maxHeight = canvasHeight;
          var maxWidth = canvasWidth;

          //按钮中心坐标
          var BtnCx = maxWidth/10*9-50;
          var BtnCy = maxHeight/5;
          var BtnWidth = 20;
          var BtnHeight = 20;

          var BtnX = BtnCx - BtnWidth/2 ;
          var BtnY = BtnCy - BtnHeight/2 ;

          //画外圈
          myPen.setColor("#4F4F4F");
          myPen.fillRect(BtnX, BtnY, BtnWidth, BtnHeight);
          myPen.paint();

          var BtnWidth2 = 14;
          var BtnHeight2 = 14;
          var lineLen = 12;
          //动画效果
          if(mouseDownFlag==1){
            if(mousePosX>=BtnX && mousePosX <= BtnX+BtnWidth &&
                mousePosY >=BtnY && mousePosY <= BtnY +BtnHeight){
              BtnWidth2 = 20;
              BtnHeight2 = 20;
              lineLen = 14;

              if (timeWidthTblIndex >= (timeWidthTblNum - 1)) {
                timeWidthTblIndex = timeWidthTblNum-1;
              }
              else {
                timeWidthTblIndex++;
              }
              //mouseclickFlag = 0;
            }
          }

          var BtnX2 = BtnCx - BtnWidth2/2 ;
          var BtnY2 = BtnCy - BtnHeight2/2 ;
          //画内圈
          myPen.setColor("#6C6C6C");
          myPen.fillRect(BtnX2, BtnY2, BtnWidth2, BtnHeight2);
          myPen.paint();

          var lhx1 = BtnCx - lineLen/2-1;
          var lhy1 = BtnCy;
          var lhx2 = BtnCx + lineLen/2-1;
          var lhy2 = BtnCy;

          //画横
          myPen.setStroke(2);
          myPen.setColor("#D0D0D0");
          myPen.drawLine(lhx1, lhy1, lhx2, lhy2);
          myPen.paint();
          myPen.setStroke(1);
        }

        ///
        //鼠标按下事件,这是传统的事件绑定,它非常简单而且稳定,适应不同浏览器.e表示事件,this指向当前元素.
        //但是这样的绑定只会在事件冒泡中运行,捕获不行.一个元素一次只能绑定一个事件函数.
        myMouseCanvas.onmousedown = function (e) {
          var e = window.event || e
          var rect = this.getBoundingClientRect();
          var mouseX = e.clientX - rect.left; //获取鼠标在canvsa中的坐标
          var mouseY = e.clientY - rect.top;

          mouseZoomDownFlag = 1;
          mouseDownFlag = 1;
          mousePosX = mouseX;

          mouseDownPlayTime = curPlayTime;
          // console.log(mouseDownPlayTime)

        }
        //鼠标松开
        myMouseCanvas.onmouseup = function (e) {
          mouseZoomDownFlag = 0;
          if (mouseDownFlag) {
            mouseUpPlayTime = curPlayTime;
            //响应鼠标拖拽状态条
            checkTimePeriods();
            //重新定位开始播放时间

            if (mouseUpPlayTime != mouseDownPlayTime)
              setNewPlayStartTimeCallback();

            //alert("mouse up");
            mouseDownFlag = 0;
          }
        };

        //鼠标移动
        myMouseCanvas.onmousemove = function (e) {
          var e = window.event || e
          var rect = this.getBoundingClientRect();
          var mouseX = e.clientX - rect.left; //获取鼠标在canvsa中的坐标
          var mouseY = e.clientY - rect.top;

          //dbgStr = "鼠标状态：" + mouseDownFlag + "鼠标坐标：" + mouseX + "," + mouseY;

          //鼠标按下状态
          if (mouseDownFlag) {
            //alert("Mouse draging");
            if (mousePosX - mouseX > 0) {
              mouseMoveDir = 1;
              mouseDownMoveStep = Math.abs(mousePosX - mouseX);
              if (mouseDownMoveStep >= 2) {
                mousePosX = mouseX;
                curPlayTime = curPlayTime + timeWidthStepTbls[timeWidthTblIndex];
                //alert("Mouse left step:"+ mouseDownMoveStep);
              }
            }
            else {
              mouseMoveDir = 0;
              mouseDownMoveStep = Math.abs(mousePosX - mouseX);
              if (mouseDownMoveStep >= 2) {
                mousePosX = mouseX;
                curPlayTime = curPlayTime - timeWidthStepTbls[timeWidthTblIndex];
                //alert("Mouse right step:"+ mouseDownMoveStep);
              }
            }
          }
          //鼠标移动
          mouseOverFlag = 1;
          mousePosX = mouseX;
          mousePosY = mouseY;

        }
        //鼠标移出区域
        myMouseCanvas.onmouseout = function (e) {
          //mouseDownFlag = 0;
          //mouseOverFlag = 0;
          //alert("Mouse out");
        }
        //鼠标进去区域
        myMouseCanvas.onmouseover = function (e) {
          var e = window.event || e
          var rect = this.getBoundingClientRect();
          var mouseX = e.clientX - rect.left; //获取鼠标在canvsa中的坐标
          var mouseY = e.clientY - rect.top;

          mouseOverFlag = 1;
          mousePosX = mouseX;
          mousePosY = mouseY;

          //mouseDownFlag = 0;
          //alert("Mouse in");
          this.style.cursor = "pointer";
        }
        myMouseCanvas.onmousewheel = function (e) {
          //alert("Mouse wheel");

          if (e == undefined)
            e = window.event; // old IE support

          var delta = e.wheelDelta / 120;

          if (delta > 0) {
            if (timeWidthTblIndex >= (timeWidthTblNum - 1)) {
              timeWidthTblIndex = timeWidthTblNum-1;
            }
            else {
              timeWidthTblIndex++;
            }
          }
          else {
            if (timeWidthTblIndex <=0) {
              timeWidthTblIndex = 0;
            }
            else {
              timeWidthTblIndex--;
            }
          }
        }
        //单击事件
        myMouseCanvas.onclick = function (e) {
          var e = window.event || e
          var rect = this.getBoundingClientRect();
          var mouseX = e.clientX - rect.left; //获取鼠标在canvsa中的坐标
          var mouseY = e.clientY - rect.top;

          mousePosX = mouseX;
          mousePosY = mouseY;

          mouseclickFlag = 1;
          mouseDownPlayTime = curPlayTime;

          // alert("click");
        }

        /*
            双击事件在进度条上时获取不到，信号
            处理方法，添加一层透明的div 于最上层 用于捕获鼠标事件
        */
        //双击事件
        myMouseCanvas.ondblclick = function (e) {
          var e = window.event || e
          var rect = this.getBoundingClientRect();
          var mouseX = e.clientX - rect.left; //获取鼠标在canvsa中的坐标
          var mouseY = e.clientY - rect.top;

          mousePosX = mouseX;
          mousePosY = mouseY;

          mousedblclickFlag = 1;
          mouseDownPlayTime = curPlayTime;
          // alert("double click");
        }
      }

    },
    startRecord(){
      this.recordVisible=false
      this.toggleRecArea(true)
      this.recording()
      this.startCapture()
    },
    recording(){
      let that = this
      this.start = new Date();
      this.end = null;

      this.recorderTime = document.getElementById("recorderTime");
      this.blinkRec = document.getElementById("blinkRec");

      this.repeat = setInterval(() => {
        that.recorderTime.innerText = parseInt(new Date() - this.start) / 1000 + "s"
      });
      this.repeatBlink = setInterval(() => {
        that.toggleBlink()
      }, 400);
    },
    //控制录屏显示隐藏
    toggleRecArea(isShow) {
      this.RECArea=isShow
    },
    //控制录屏标识闪烁
    toggleBlink() {
      this.blinkShow = !this.blinkShow;
      let displayAttr = this.blinkShow ? 'hidden' : ''
      this.blinkRec.style.visibility = displayAttr;
    },
    stopRecord(){
      this.recordVisible=true
      this.stopCapture()

      clearInterval(this.repeat)
      this.repeat=null
      clearInterval(this.repeatBlink)
      this.repeatBlink=null
      this.toggleRecArea(false)
      clearInterval(this.timer)
      this.timer=null

    },
    async startCapture() {

      this.videoElement=document.querySelector("video")
      let that = this
      this.recorder = new MediaRecorder(that.videoElement.mozCaptureStream ? that.videoElement.mozCaptureStream(25) : that.videoElement.captureStream(25) ,{
        videoBitsPerSecond : 600000,
        mimeType: 'video/webm;'
      });

      // this.recorder.onstop = function() {
      //   let duration = Date.now() - starttime;
      //   let buggyBlob = new Blob(that.mediaParts, { type: 'video/webm' });
      //
      //   // v1: callback-style
      //   ysFixWebmDuration(buggyBlob, duration, function(fixedBlob) {
      //     displayResult(fixedBlob);
      //   });
      //
      //   // v2: promise-style, disable logging
      //   ysFixWebmDuration(buggyBlob, duration, {logger: false})
      //       .then(function(fixedBlob) {
      //         displayResult(fixedBlob);
      //       });
      // };

      let startTime = new Date().getTime();
      this.recorder.start(60000);
      this.timer = setInterval(() => {
        if (parseInt(new Date() - startTime )/1000 > 1800) {
          that.stopRecord()
        }
      });
    },
    stopCapture() {
      // this.tracks = this.video.srcObject.getTracks();
      // this.tracks.forEach(track => {
      //   track.stop();
      // });
      this.recorder.stop();
      this.recorder.addEventListener('dataavailable',(event)=>{
        let videoUrl = URL.createObjectURL(event.data, {type:'video/webm'});
        this.recordElement.srcObject = null;
        this.recordElement.src = videoUrl;
      })
      let that = this
      setTimeout(()=>{
        that.mydownload()
      },500)
    },
    mydownload(){
      let time = moment(new Date()).format("YYYYMMDDHHmmss");
      let timeName = time.substring(0,4)+"年"+time.substring(4,6)+"月"+time.substring(6,8)+"日"+time.substring(8,10)+"时"+time.substring(10,12)+"分"+time.substring(12,14)+"秒"
      // const name = this.monitorname+"-"+new Date().toISOString().slice(0,19).replace('T',' ').replace(' ','').replace(/:/g,'-');
      const name = this.address+"-"+timeName;
      const a = document.createElement('a');
      a.href = this.recordElement.src;
      a.download = `${name}.mp4`;
      document.body.appendChild(a);
      a.click();
    },
  }
}
</script>
<style lang="scss" scoped>
.elevator-door{
  background-color: white;
}
.monitoring {
  padding: 12px;
}
.status-cards {
  display: flex;
  margin-top: 2px;
}
.card-container {
  flex-grow: 1;
/*  flex-basis: 1;*/
  flex-shrink: 0;
  min-height: 123px;
  // min-width: calc(16.66% - 10px);
  background-color: white;
  margin-left: 2px;
  padding: 15px 0 15px 0;
  &:first-child {
    margin-left: 0;
  }
  .card-title {
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-left: 25px;
    }
    span {
      margin-left: 16px;
    }
  }
  .card-infos {
    margin-top: 10px;
    text-align: center;
    .info-desc {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
    }
    .info-data {
      font-size: 18px;
      margin-top: 10px;
    }
  }
}
.elevator-infos {
  display: flex;
  // margin-top: 24px;
}
.video-view {
  padding: 8px;
  background-color: white;
  max-height: 600px;
}
.video-operations {
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.info-view {
  margin-left: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  background-color: white;
  overflow: hidden;
}
.tab-container {
  padding: 0 12px;
  background-color: white;
}
.video{
  display: inline-block;
  width: 780px;
  height: 438px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  margin-right: 4px;
}
.elevator-door {
  flex-shrink: 0;
  // width: 396px;
  // height: 592px;
  width: calc((100vh - 300px)*0.67);
  height: calc(90vh - 300px);
  background-color: #ffffff;
  border-radius: 2px;
  padding: 8px;
  .door-image {
    position: relative;
    width: 100%;
    height: 95%;
    // background-image: url(~@/assets/image/huichuan/door.png);
    background-size: 100% 100%;
    overflow: hidden;
    >img {
      width: 100%;
      height: 100%;
    }
    .door-num {
      width: 64px;
      height: 36px;
      margin: 0 auto;
      background-image: url(~@/assets/image/huichuan/doornum.png);
      display: flex;
      justify-content: space-around;
      align-items: center;
      .direction {
        line-height: 0;
      }
      .num {
        font-size: 20px;
        color: #ff9300;
      }
    }
  }
}
.video:hover{
  display: block;
}
.captureHoverArea {
  position: absolute;
  z-index: 99999;
  font-weight: bold;
  left: 30px;
  top: 160px;
  color: red;
  //display: none;
}
.canvass{
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}
</style>