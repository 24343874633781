<template>
  <div class="huichuan">
    <div class="top-infos">
      <div class="top-item">
        <span class="info-name">内部编号: </span>
        <span class="info-value">{{deviceinfo.devicename}}</span>
      </div>
      <!-- <div class="top-item">
        <span class="info-name">控制器类型: </span>
        <span class="info-value">{{deviceinfo.terminaltypename}}</span>
      </div> -->
      <div class="top-item">
        <span class="info-name">系统运行时间: </span>
        <span class="info-value" style="color: #ff9300">{{topInfos.time}}</span>
      </div>
      <div class="top-item">
        <span class="info-name">运行次数: </span>
        <span class="info-value" style="color: #ff9300">{{topInfos.count}}</span>
      </div>
      <div class="top-buttons">
        <a-button type="primary" @click="duanzi">端子状态</a-button>
        <a-button style="margin-left: 8px;" @click="showEvents=true">电梯事件</a-button>
      </div>
    </div>
    <div class="bottom-content">
      <div class="elevator-door">
        <div class="door-image">
          <img v-if="status.kaimen=='关门'" src="~@/assets/image/huichuan/door-close.gif">
          <img v-else-if="status.kaimen=='开门'" src="~@/assets/image/huichuan/door-open.gif">
          <img v-else src="~@/assets/image/huichuan/door.png">
          <div style="position: absolute; top: 5.6%; width: 100%;">
            <div class="door-num">
              <div class="direction">
                <img v-if="status.xiangshang" src="~@/assets/image/huichuan/shangbtn.png">
                <img v-else-if="status.xiangxia" src="~@/assets/image/huichuan/xiabtn.png">
              </div>
              <div class="num">{{status.floorNum}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ai-items">
        <div class="ai-item-image">
          <img v-if="true||status.network=='4G'" src="~@/assets/image/huichuan/4g.png">
          <img v-else-if="status.network=='5G'" src="~@/assets/image/huichuan/5g.png">
          <img v-else-if="status.network=='3G'" src="~@/assets/image/huichuan/3g.png">
        </div>
        <div class="ai-item-text">网络</div>
        <div class="ai-item-image">
          <img src="~@/assets/image/huichuan/znyj.png">
        </div>
        <div class="ai-item-text">智能硬件</div>
        <div class="ai-item-image">
          <img src="~@/assets/image/huichuan/kongzq.png">
        </div>
        <div class="ai-item-text">控制器</div>
        <div class="ai-item-image">
          <img src="~@/assets/image/huichuan/bxll.png">
        </div>
        <div class="ai-item-text">不限制流量</div>
        <div class="ai-item-image" style="margin-top: 48px;">
          <img v-if="status.xiangshang" src="~@/assets/image/huichuan/shang.png">
          <img v-else-if="status.xiangxia" src="~@/assets/image/huichuan/xia.png">
          <img v-else src="~@/assets/image/huichuan/tingzhi.png">
        </div>
      </div>
      <div class="right-content">
        <div class="lift-detail">
          <huichuan-base-info :liftid="$route.params.liftid"></huichuan-base-info>
        </div>
        <div class="status-cards">
          <a-row class="status-cards-row">
            <a-col :span="6" class="status-card">
              <div class="card-image">
                <img v-if="status.jianxiu" src="~@/assets/image/huichuan/jianxiu.png">
                <img v-else src="~@/assets/image/huichuan/jianxiublue.png">
              </div>
              <div class="card-text">检修</div>
            </a-col>
            <a-col :span="6" class="status-card">
              <div class="card-image">
                <img v-if="status.guzhang" src="~@/assets/image/huichuan/guzhang.png">
                <img v-else src="~@/assets/image/huichuan/guzhangblue.png">
              </div>
              <div class="card-text">故障</div>
            </a-col>
            <a-col :span="6" class="status-card">
              <div class="card-image">
                <img v-if="status.chaozai" src="~@/assets/image/huichuan/chaozai.png">
                <img v-else src="~@/assets/image/huichuan/chaozaiblue.png">
              </div>
              <div class="card-text">超载</div>
            </a-col>
            <a-col :span="6" class="status-card">
              <div class="card-image">
                <img v-if="status.manzai" src="~@/assets/image/huichuan/manzai.png">
                <img v-else src="~@/assets/image/huichuan/manzaiblue.png">
              </div>
              <div class="card-text">满载</div>
            </a-col>
          </a-row>
          <a-row class="status-cards-row">
            <a-col :span="6" class="status-card">
              <div class="card-image">
                <img v-if="status.xiaofang" src="~@/assets/image/huichuan/xiaofang.png">
                <img v-else src="~@/assets/image/huichuan/xiaofangblue.png">
              </div>
              <div class="card-text">消防</div>
            </a-col>
            <a-col :span="6" class="status-card">
              <div class="card-image">
                <img v-if="status.zhuting" src="~@/assets/image/huichuan/zhut.png">
                <img v-else src="~@/assets/image/huichuan/zhutblue.png">
              </div>
              <div class="card-text">驻停</div>
            </a-col>
            <a-col :span="6" class="status-card">
              <div class="card-image">
                <img v-if="status.pingceng" src="~@/assets/image/huichuan/pingceng.png">
                <img v-else src="~@/assets/image/huichuan/pingcengblue.png">
              </div>
              <div class="card-text">平层</div>
            </a-col>
            <a-col :span="6" class="status-card">
              <div class="card-image">
                <img v-if="status.kongzhiqi" src="~@/assets/image/huichuan/kzmm.png">
                <img v-else src="~@/assets/image/huichuan/kzmmblue.png">
              </div>
              <div class="card-text">控制器密码</div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <huichuan-duanzi v-if="showDuanzi" :liftnum="liftnum" :liftcode="liftcode" :devicenum="devicenum" @close="showDuanzi=false"></huichuan-duanzi>
    <huichuan-events v-if="showEvents" :liftnum="liftnum" :liftcode="liftcode" :devicenum="devicenum" @close="showEvents=false"></huichuan-events>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import HuichuanBaseInfo from './HuichuanBaseInfo'
import HuichuanDuanzi from './HuichuanDuanzi'
import HuichuanEvents from './HuichuanEvents'
export default {
  components: {
    HuichuanBaseInfo,
    HuichuanDuanzi,
    HuichuanEvents,
  },
  props: ['deviceinfo', 'liftnum', 'liftcode', 'devicenum'],
  data() {
    return {
      topInfos: {
        time: '',
        count: '',
      },
      status: {
        network: '',
        floorNum: '',
        kaimen: '',
        xiangshang: true,
        xiangxia: true,
        jianxiu: true,
        guzhang: true,
        chaozai: true,
        manzai: true,
        xiaofang: true,
        zhuting: true,
        pingceng: true,
        kongzhiqi: true,
      },
      showDuanzi: false,
      showEvents: false,
    }
  },
  computed: {
    ...mapState({
      socketMessage: state => state.socketMessage
    })
  },
  watch: {
    socketMessage(val) {
      if(val) {
        // {"data":{"door_zone":"01","accumulative_door_num":"3465289","car_position":"5","accumulative_run_num":"600009","has_passenger":"01","car_status":"01","accumulative_run_time":"67543900","car_direction":"01","temperature":"20","service_mode":"1","door_status":"00"},"returnmsg":"OK","returncode":0,"sessionid":"e760baf706fe4d4b98473602b3af36e3"}
        // console.log(val);
        if(val.type == '2') {
          this.packageDatas(val.data);
        }else if(val.type == '3') {
          this.packageDatas2(val.data);
        }
      }
    }
  },
  methods: {
    packageDatas(datas) {
      if(datas) {
        // console.log(datas['运行方向'])
        // console.log(datas['楼层'])
        let xiangshang = datas['运行方向'] && datas['运行方向'].indexOf('上') > -1;
        this.status.xiangshang = xiangshang;
        let xiangxia = datas['运行方向'] && datas['运行方向'].indexOf('下') > -1;
        this.status.xiangxia = xiangxia;
        // this.status.pingceng = xiangshang || xiangxia;
        if(datas['楼层']) {
          let floorNum = parseInt(datas['楼层'], 10);
          this.status.floorNum = floorNum;
        }
        this.status.kaimen = datas['开关门状态'];
        this.status.network = datas['网络类型']||'';
        let jianxiu = datas['检修状态'] && datas['检修状态'] == '正常';
        this.status.jianxiu = jianxiu;
        let guzhang = datas['电梯故障代码'] && datas['电梯故障代码'] == '0';
        this.status.guzhang = guzhang;
        let chaozai = datas['电梯轿厢超载信号'] && datas['电梯轿厢超载信号'] == '正常';
        this.status.chaozai = chaozai;
        let manzai = datas['电梯轿厢满载信号'] && datas['电梯轿厢满载信号'] == '正常';
        this.status.manzai = manzai;
        let xiaofang = datas['电梯消防状态信号'] && datas['电梯消防状态信号'] == '正常';
        this.status.xiaofang = xiaofang;
        let zhuting = datas['电梯锁梯信号'] && datas['电梯锁梯信号'] == '锁梯';
        this.status.zhuting = !zhuting;
        let pingceng = datas['电梯门区信号'] && datas['电梯门区信号'] == '门区内';
        this.status.pingceng = !pingceng;
        let kongzhiqi = datas['电梯控制器有密码'] && datas['电梯控制器有密码'] == '无密码';
        this.status.kongzhiqi = kongzhiqi;
      }
    },
    packageDatas2(datas) {
      if(datas) {
        this.topInfos.time = datas['系统运行时间']||'';
        this.topInfos.count = datas['运行次数']||'';
      }
    },
    duanzi() {
      this.showDuanzi=true;
      this.$emit('show-duanzi');
    }
  }
}
</script>
<style lang="scss" scoped>
.huichuan {
  padding: 12px;
}
.top-infos {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 8px;
  background-color: white;
  border-radius: 2px;
}
.top-item {
  padding: 0 12px 0 8px;
  line-height: 32px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}
.top-buttons {
  margin-left: 20px;
}
.bottom-content {
  display: flex;
  margin-top: 10px;
}
.elevator-door {
  flex-shrink: 0;
  // width: 396px;
	// height: 592px;
  width: calc((100vh - 190px)*0.67);
	height: calc(100vh - 190px);
	background-color: #ffffff;
  border-radius: 2px;
  padding: 8px;
  .door-image {
    position: relative;
    width: 100%;
    height: 100%;
    // background-image: url(~@/assets/image/huichuan/door.png);
    background-size: 100% 100%;
    overflow: hidden;
    >img {
      width: 100%;
      height: 100%;
    }
    .door-num {
      width: 64px;
      height: 36px;
      margin: 0 auto;
      background-image: url(~@/assets/image/huichuan/doornum.png);
      display: flex;
      justify-content: space-around;
      align-items: center;
      .direction {
        line-height: 0;
      }
      .num {
        font-size: 20px;
        color: #ff9300;
      }
    }
  }
}
.ai-items {
  flex-shrink: 0;
  width: 80px;
	// height: 592px;
  height: calc(100vh - 190px);
  // min-height: 500px;
	background-color: #ffffff;
	border-radius: 2px;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .ai-item-image {
    line-height: 0;
    margin-top: 24px;
  }
  .ai-item-text {
    font-size: 14px;
    margin-top: 5px;
  }
}
.right-content {
  margin-left: 8px;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .lift-detail {
    background-color: white;
    padding: 16px;
    border-radius: 2px;
  }
  .status-cards {
    height: calc(100vh - 335px);
    .status-cards-row {
      height: 35%;
    }
    .status-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 23%;
      margin-left: 2.6666%;
      background-color: white;
      height: calc(100% - 16px);
      margin-top: 16px;
      // padding: 24px 0;
      &:first-child {
        margin-left: 0;
      }
      .card-image {
        // margin-top: 15%;
        width: 30%;
        img {
          width: 100%;
        }
      }
      .card-text {
        margin-top: 8px;
      }
    }
  }
}
</style>