<template>
  <div :breadcrumb="breadcrumb">
    <div class="time-monitoring">
      <div class="tab-container tab-bottom0 tab-left0">
        <a-tabs v-model="activeKey" size="small">
          <a-tab-pane v-if="devicenum1" key="tab1" tab="实时监控"></a-tab-pane>
          <a-tab-pane v-if="devicenum2" key="tab2" tab="汇川平台"></a-tab-pane>
        </a-tabs>
      </div>
      <monitoring v-if="activeKey == 'tab1'" :terminaltype="terminaltype" :deviceinfo="deviceinfo1" :liftnum="liftnum" :address="address" :liftcode="liftcode" :devicenum="devicenum1"></monitoring>
      <huichuan-platform v-if="activeKey == 'tab2'" :deviceinfo="deviceinfo2" :liftnum="liftnum" :liftcode="liftcode" :devicenum="devicenum2" @show-duanzi="sendDevice3"></huichuan-platform>
    </div>
  </div>
</template>
<script>
import Monitoring from './Monitoring'
import HuichuanPlatform from './HuichuanPlatform.vue'
export default {
  name: 'timeLiftMonitoring',
  components: {
    Monitoring,
    HuichuanPlatform,
  },
  data() {
    return {
      breadcrumb: [
        ...this.$route.params.prePages,
        {
          name: '实时监控',
          path: ''
        },
      ],
      activeKey: 'tab1',
      ws: null,
      liftnum: '',
      address:'',
      liftcode: '',
      terminaltype:'',
      devicenum1: '',
      devicenum2: '',
      deviceinfo1: {},
      deviceinfo2: {},
      interval1: null,
      interval2: null,
      interval3: null,
    }
  },
  watch: {
    activeKey(val) {
      if(val == 'tab2') {
        this.sendDevice2();
      }
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   console.log('=beforeRouteEnter=')
  //   next();
  // },
  // beforeRouteLeave(to, from, next) {
  //   console.log('=beforeRouteLeave=')
  //   next();
  // },
  created() {
    // console.log('=created=')
    this.liftnum = this.$route.params.liftnum;
    this.address=this.$route.params.address;
    this.liftcode = this.$route.params.liftcode;
    if(this.$route.params.deviceinfos && this.$route.params.deviceinfos.length) {
      // console.log(this.$route.params.deviceinfos)
      this.$route.params.deviceinfos.forEach(item => {
        if(item.terminalmodlenum == '01') {
          // 汇川设备
          this.deviceinfo2 = item;
          this.devicenum2 = item.devicenum;
        }else if(item.terminalmodlenum == '02') {
          // AI设备
          this.deviceinfo1 = item;
          this.terminaltype=item.terminaltype
          this.devicenum1 = item.devicenum;
        }
      })
    }
    if(!this.devicenum1) {
      this.activeKey = 'tab2';
    }
    this.$ws.connect((ws) => {
      this.ws = ws;
      // ws.send('{"devicenum":"001"}')
      if(!this.devicenum1) {
        this.sendDevice2();
      }else {
        this.sendDevice1();
      }
    });
  },
  beforeDestroy() {
    // console.log('=beforeDestroy=')
    clearInterval(this.interval1);
    clearInterval(this.interval2);
    clearInterval(this.interval3);
    this.$ws.disconnect();
  },
  methods: {
    clickLeftMenu(event) {
      this.tabKey = event.key
    },
    sendDevice1() {
      if(this.interval1) {
        // 已启动连接
        return;
      }
      if(this.ws && this.liftnum && this.devicenum1) {
        let wsParams = {
          liftnum: this.liftnum,
          devicenum: this.devicenum1,
          terminalmodlenum: '02',
        }
        this.ws.send(JSON.stringify(wsParams));
        this.interval1 = setInterval(() => {
          this.ws.send(JSON.stringify(wsParams));
        }, 1*60*1000);
      }
    },
    sendDevice2() {
      if(this.interval2) {
        // 已启动连接
        return;
      }
      if(this.ws && this.liftnum && this.devicenum2) {
        let wsParams = {
          liftnum: this.liftnum,
          devicenum: this.devicenum2,
          terminalmodlenum: '01',
        }
        this.ws.send(JSON.stringify(wsParams));
        this.interval2 = setInterval(() => {
          this.ws.send(JSON.stringify(wsParams));
        }, 3*60*1000);
      }
    },
    sendDevice3() {
      if(this.interval3) {
        // 已启动连接
        return;
      }
      if(this.ws && this.liftnum && this.devicenum2) {
        let wsParams = {
          liftnum: this.liftnum,
          devicenum: this.devicenum2,
          terminalmodlenum: '01',
          istermimalstatus: 'true',
        }
        this.ws.send(JSON.stringify(wsParams));
        this.interval3 = setInterval(() => {
          this.ws.send(JSON.stringify(wsParams));
        }, 3*60*1000);
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.tab-container {
  padding: 0 12px;
  background-color: white;
}
</style>